import React from "react"
import {graphql, Link } from "gatsby"
import {RichText } from 'prismic-reactjs'
import Img from "gatsby-image/withIEPolyfill";

import Layout from "../components/layout"
import SEO from "../components/seo"
import {rootPath, blogPostPath} from "../utils/linkResolver";
import {blogDate} from "../utils/blogDate";
import {TikLink} from "../components/helper";

import logo from "../images/made-by-logo.svg";
import BlogPen from "../images/blog-pen.inline.svg";


const Blog = ({location, data, pageContext}) => {
  const lang = pageContext.langKey;

  const t = data.prismic.allBlogpages.edges[0].node;

  const postEdges = data.prismic.allPosts.edges




  function fluidWithCustomSize(fl) {
    fl.sizes = '(max-width: 500px) 100vw, 33.33vw'
    return fl;
  }

  return (
    <Layout location={location} data={data} activeKey={'blog'} lang={lang}>
    {/* <Layout location={location} scrollMagic={scrollMagic}> */}
      <SEO title={t.title}
        description={t.metaDescription}
      />

      <section className="blog-articles">
        <div className="section-logo">
          <Link className="made-by-logo" to={rootPath({lang})}>
            <img alt="made.by Logo" src={logo} />
          </Link>
        </div>

        <div className="blogheader">
          <h1 className="blogheader-headline">{t.headline}</h1>
          <div className="blogheader-subline">{t.subheadline}</div>
          <BlogPen className="blogheader-pen" />
        </div>


        <div className="blog-articles-list">
          {postEdges.map((edge) => (
            <div className="blog-articles-item" key={edge.node._meta.uid}>
              <Link to={blogPostPath(edge.node._meta)} className={"blog-articles-item-cover"}>
                {/* {edge.node.cover && <img  className="blog-articles-item-cover-img"
                      srcSet={`${edge.node.cover.square360.url} 360w, ${edge.node.cover.square540.url} 540w, ${edge.node.cover.square720.url} 720w`}
                      sizes="(max-width: 500px) 100vw, 33.33vw"
                      src={edge.node.cover.square540.url} alt={edge.node.cover.alt}
                      alt={edge.node.cover.alt}/>
                } */}
                { edge.node.imageSharp && <Img 
                  className="blog-articles-item-cover-img"
                  fluid={fluidWithCustomSize(edge.node.imageSharp.childImageSharp.fluid)}
                  alt={edge.node.image && edge.node.image.alt}
              />}
                <span className="blog-articles-item-date">
                  {blogDate(edge.node.date, lang)}
                </span>
              </Link>
              <div className="blog-articles-item-content">
                <Link to={blogPostPath(edge.node._meta)} className="blog-articles-item-headline">
                  {RichText.render(edge.node.title)}
                </Link>
                <div className="blog-articles-item-abstract">
                  {RichText.render(edge.node.abstract)}
                </div>
                <TikLink to={blogPostPath(edge.node._meta)} className="link-magenta">
                  {t.read_article}
                </TikLink>
              </div>
              <div className="blog-articles-item-link">
                <TikLink to={blogPostPath(edge.node._meta)} className="link-magenta">
                  {t.read_article}
                </TikLink>
              </div>
            </div>
          ))}
        </div>

      </section>

    </Layout>
  )
}


export const fragmentBlogPostFields =  graphql`
  fragment fragmentBlogPostFields on PRISMIC_Post  {
    title
    date
    abstract
    image
    imageSharp(crop: "square") {
      childImageSharp {
        fluid(srcSetBreakpoints: [ 250, 360, 672 ], maxWidth: 672, quality: 70) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    _meta {
      uid
      lang
      type
    }
  }

  fragment fragmentBlogpageFields on PRISMIC_Blogpage {
    title
    metaDescription
    headline
    subheadline
    read_article
    post_all_articles
  }
`


export const query = graphql`
  fragment BlogFragment on Query {
    prismic {
      ...fragmentPrismicLayout
      allPosts(sortBy: date_DESC, lang: $lang) {
        edges {
          node {
            ...fragmentBlogPostFields
          }
        }
      }
      allBlogpages(lang: $lang) {
        edges {
          node {
            ...fragmentBlogpageFields
          }
        }
      }
    }
  }
  query($lang: String = "de-de") {
    ...BlogFragment
  }
`;

export default Blog;
